<template>
  <div class="mask-hot-wrap">
    <div class="mt20">
      <el-card shadow="always" class="video-card">
        <p class="intro-title mb20">{{ video.title }}</p>

        <video-viewer
          class="mt20"
          width="100%"
          height="200px"
          :title="video.title"
          :cover="video.cover"
          :url="video.url"
        ></video-viewer>
      </el-card>
    </div>

    <div v-for="(tItem, tIndex) in tableData" :key="tIndex" class="module-wrap">
      <div class="module-container">
        <div class="module-title">{{ tItem.name }}</div>
        <div class="module-body">
          <el-table :data="tItem.list" border stripe style="width: 100%">
            <el-table-column align="center" label="效果图">
              <template slot-scope="scope">
                <img
                  v-for="(item, index) in scope.row.url"
                  :key="index"
                  class="table-img-demo"
                  @click="showPic(item)"
                  v-lazy="item"
                  alt=""
                />
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <transition name="el-fade-in-linear">
      <el-image-viewer
        v-if="preview"
        :url-list="[currentPic]"
        :on-close="
          () => {
            preview = false;
          }
        "
      />
    </transition>
  </div>
</template>

<script>
import VideoViewer from '@/components/VideoViewer';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';

export default {
  data() {
    return {
      preview: false,
      currentPic: '',
      video: {
        title: '包装定制介绍',
        cover: require('@/assets/images/video_cover7.png'),
        url:
          'http://39.99.237.105/resource/第五页包装定制介绍/包装定制介绍.mp4',
      },
      tableData: [
        {
          name: '包装膜案例',
          list: [
            {
              url: [
                'http://39.99.237.105/resource/第五页包装定制介绍/包装膜案例/定制膜2.jpg',
                'http://39.99.237.105/resource/第五页包装定制介绍/包装膜案例/定制膜照片1.png',
                'http://39.99.237.105/resource/第五页包装定制介绍/包装膜案例/定制磨砂膜3.jpg',
              ],
            },
          ],
        },
        {
          name: '包装袋案例',
          list: [
            {
              url: [
                'http://39.99.237.105/resource/第五页包装定制介绍/包装袋案例/定制5片装袋子.jpg',
                'http://39.99.237.105/resource/第五页包装定制介绍/包装袋案例/定制10片装袋子.jpg',
              ],
            },
          ],
        },
        {
          name: '包装盒案例',
          list: [
            {
              url: [
                'http://39.99.237.105/resource/第五页包装定制介绍/包装盒案例/10片装盒子-实物图.jpg',
                'http://39.99.237.105/resource/第五页包装定制介绍/包装盒案例/50片装盒子-实物图.jpg',
              ],
            },
          ],
        },
      ],
    };
  },
  components: {
    VideoViewer,
    ElImageViewer,
  },
  computed: {
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
  methods: {
    showPic(url) {
      this.preview = true;
      this.currentPic = url;
    },
  },
};
</script>

<style lang="less" scoped>
// /deep/ .el-image-viewer__wrapper .el-icon-circle-close {
//   color: #fff !important;
// }

.mask-hot-wrap {
  min-height: calc(100vh - 80px);

  .video-card {
    width: 420px;
    margin: 0 auto;

    .intro-title {
      .title-24;
      text-align: center;
    }
  }

  .module-wrap {
    background-color: #fff;
    .module-container {
      padding-bottom: 40px;

      .module-title {
        background-image: linear-gradient(to bottom, #f7f7f7, #fff);

        .title-24;
        font-size: 30px;
        padding: 20px 0;
        text-align: center;
      }

      .module-body {
        max-width: @mainContainerWidth;
        margin: 0 auto;

        .table-img-demo {
          height: 200px;
          border-radius: 6px;
          border: 1px solid #eee;
          background-color: #fff;

          & + .table-img-demo {
            margin-left: 20px;
          }

          &:hover {
            cursor: zoom-in;
          }
        }
      }
    }
  }
}

// 移动端
@media screen and (max-width: 768px) {
  .intro-title,
  .module-title {
    font-size: 18px !important;
  }

  .video-card {
    width: 100% !important;
  }

  .table-img-demo {
    height: 70px !important;
  }
}
</style>
